const PATHS = {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  BURNDOWN: '/burndown',
  KANBAN: '/kanbanboard',
  BACKLOGANDSPRINT: '/backlogandsprint',
  AGILESTUDY: '/agilestudy',
};

export default PATHS;
